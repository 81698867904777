"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _client = require("@apollo/client");

var _notification = _interopRequireDefault(require("antd/lib/notification"));

var _locales = require("@meepshop/locales");

var _useSendResetPswMail = require("../gqls/useSendResetPswMail");

// import
// graphql import
// definition
var _default = function _default(cname) {
  var _useTranslation = (0, _locales.useTranslation)('login'),
      t = _useTranslation.t;

  var _useMutation = (0, _client.useMutation)(_useSendResetPswMail.sendResetPasswordEmail, {
    onCompleted: function onCompleted(data) {
      switch (data.sendResetPasswordEmail.status) {
        case 'OK':
          _notification["default"].success({
            message: t('forget-password-success')
          });

          break;

        case 'FAIL_CANNOT_FIND_USER':
          _notification["default"].error({
            message: t('forget-password-failure-message'),
            description: t('cannot-find-user')
          });

          break;

        default:
          _notification["default"].error({
            message: t('forget-password-failure-message'),
            description: data.sendResetPasswordEmail.status
          });

          break;
      }
    }
  }),
      _useMutation2 = (0, _slicedToArray2["default"])(_useMutation, 1),
      mutation = _useMutation2[0];

  return (0, _react.useCallback)(function (_ref) {
    var email = _ref.email;
    mutation({
      variables: {
        input: {
          cname: cname,
          email: email,
          type: 'SHOPPER'
        }
      }
    });
  }, [cname, mutation]);
};

exports["default"] = _default;