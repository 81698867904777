"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.REQUIRED = exports.HIDDEN = exports.SHOPPER = exports.FORGET_PSW = exports.SIGNUP = exports.LOGIN = void 0;
// typescript definition
// definition
var LOGIN = 'LOGIN';
exports.LOGIN = LOGIN;
var SIGNUP = 'SIGNUP';
exports.SIGNUP = SIGNUP;
var FORGET_PSW = 'FORGET_PSW';
exports.FORGET_PSW = FORGET_PSW;
var SHOPPER = 'SHOPPER';
exports.SHOPPER = SHOPPER;
var HIDDEN = 'HIDDEN';
exports.HIDDEN = HIDDEN;
var REQUIRED = 'REQUIRED';
exports.REQUIRED = REQUIRED;