"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getLogin = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _form = require("./form");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  query getLogin {\n    viewer {\n      id\n      store {\n        id\n        setting {\n          shopperLoginMessageEnabled\n          shopperLoginMessage\n        }\n        ...formFragment\n      }\n    }\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var getLogin = (0, _client.gql)(_templateObject(), _form.formFragment);
exports.getLogin = getLogin;