"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.signupFormFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment signupFormFragment on Store {\n    id\n    memberGroupPromoRules {\n      id\n    }\n    setting {\n      checkoutFields {\n        address\n        birthday\n        gender\n        mobile\n        name\n        tel\n      }\n    }\n    shippableCountries {\n      id\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var signupFormFragment = (0, _client.gql)(_templateObject());
exports.signupFormFragment = signupFormFragment;