"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.date.to-string.js");

require("core-js/modules/es.function.name.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _client = require("@apollo/client");

var _notification = _interopRequireDefault(require("antd/lib/notification"));

var _link = require("@meepshop/link");

var _locales = require("@meepshop/locales");

var _AdTrack = _interopRequireDefault(require("@meepshop/context/lib/AdTrack"));

var _constants = require("../constants");

var _useSignup = require("../gqls/useSignup");

// typescript import
// import
// graphql import
// definition
var _default = function _default(setOptions) {
  var _useTranslation = (0, _locales.useTranslation)('login'),
      t = _useTranslation.t;

  var router = (0, _link.useRouter)();
  var adTrack = (0, _react.useContext)(_AdTrack["default"]);

  var _useMutation = (0, _client.useMutation)(_useSignup.signup, {
    onCompleted: function onCompleted(data) {
      if (data.createShopper.__typename === 'CreateShopperError') {
        _notification["default"].error({
          message: t('signup-failure-message')
        });

        return;
      }

      _notification["default"].success({
        message: t('signup-success')
      });

      setOptions(_constants.LOGIN);
      adTrack.completeRegistration();
    }
  }),
      _useMutation2 = (0, _slicedToArray2["default"])(_useMutation, 2),
      mutation = _useMutation2[0],
      loading = _useMutation2[1].loading;

  return {
    loading: loading,
    save: (0, _react.useCallback)(function (_ref) {
      var email = _ref.email,
          password = _ref.password,
          registeredCode = _ref.registeredCode,
          mobile = _ref.mobile,
          addressAndZipCode = _ref.addressAndZipCode,
          birthday = _ref.birthday,
          gender = _ref.gender,
          name = _ref.name,
          tel = _ref.tel,
          street = _ref.street;
      mutation({
        variables: {
          input: {
            type: 'SHOPPER',
            email: email,
            password: password,
            registeredCode: registeredCode,
            mobile: mobile,
            birthday: !birthday ? null : {
              year: new Date(birthday).getFullYear(),
              month: new Date(birthday).getMonth() + 1,
              day: new Date(birthday).getDate()
            },
            name: name,
            tel: tel,
            gender: gender,
            address: {
              countryId: addressAndZipCode === null || addressAndZipCode === void 0 ? void 0 : addressAndZipCode.address[0],
              cityId: addressAndZipCode === null || addressAndZipCode === void 0 ? void 0 : addressAndZipCode.address[1],
              areaId: addressAndZipCode === null || addressAndZipCode === void 0 ? void 0 : addressAndZipCode.address[2],
              zipCode: addressAndZipCode === null || addressAndZipCode === void 0 ? void 0 : addressAndZipCode.zipCode,
              street: street
            },
            source: router.asPath
          }
        }
      });
    }, [mutation, router])
  };
};

exports["default"] = _default;