"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loginFormFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _gqls = require("@meepshop/line/lib/gqls");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment loginFormFragment on LineLoginSetting {\n    isLoginEnabled\n    ...lineFragment\n  }\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var loginFormFragment = (0, _client.gql)(_templateObject(), _gqls.lineFragment);
exports.loginFormFragment = loginFormFragment;