"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es.regexp.exec.js");

require("core-js/modules/es.string.replace.js");

require("core-js/modules/es.string.starts-with.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _client = require("@apollo/client");

var _notification = _interopRequireDefault(require("antd/lib/notification"));

var _locales = require("@meepshop/locales");

var _link = require("@meepshop/link");

var _useLogin = require("../gqls/useLogin");

// import
// graphql import
// definition
var _default = function _default() {
  var _useTranslation = (0, _locales.useTranslation)('login'),
      t = _useTranslation.t;

  var _useRouter = (0, _link.useRouter)(),
      replace = _useRouter.replace,
      previousUrl = _useRouter.previousUrl,
      asPath = _useRouter.asPath;

  var _useMutation = (0, _client.useMutation)(_useLogin.login, {
    onCompleted: function onCompleted(data) {
      if (data.login.status !== 'OK') {
        _notification["default"].error({
          message: t('invalid-email-or-password')
        });

        return;
      }

      _notification["default"].success({
        message: t('login-success')
      }); // 團購活動頁面登入後需導回團購頁面而且通常都是由外部連結直接進入這頁面，故 previousURL 會是 /


      // 團購活動頁面登入後需導回團購頁面而且通常都是由外部連結直接進入這頁面，故 previousURL 會是 /
      if (asPath.startsWith('/events/group-buy')) {
        replace(asPath);
        return;
      }

      replace(previousUrl);
    }
  }),
      _useMutation2 = (0, _slicedToArray2["default"])(_useMutation, 1),
      mutation = _useMutation2[0];

  return (0, _react.useCallback)(function (input) {
    mutation({
      variables: {
        input: input
      }
    });
  }, [mutation]);
};

exports["default"] = _default;