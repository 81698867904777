"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = require("react");

var _constants = require("../constants");

// import
// definition
var _default = function _default() {
  return (0, _react.useCallback)(function (text, fieldStatus) {
    return fieldStatus === _constants.REQUIRED ? "".concat(text, " *") : text;
  }, []);
};

exports["default"] = _default;