"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formFragment = void 0;

var _taggedTemplateLiteral2 = _interopRequireDefault(require("@babel/runtime/helpers/taggedTemplateLiteral"));

var _client = require("@apollo/client");

var _sendResetPswMailForm = require("./sendResetPswMailForm");

var _signupForm = require("./signupForm");

var _loginFrom = require("./loginFrom");

function _templateObject() {
  var data = (0, _taggedTemplateLiteral2["default"])(["\n  fragment formFragment on Store {\n    id\n    lineLoginSetting {\n      ...loginFormFragment\n    }\n    ...sendResetPswMailFormFragment\n    ...signupFormFragment\n  }\n\n  ", "\n  ", "\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

// definition
var formFragment = (0, _client.gql)(_templateObject(), _sendResetPswMailForm.sendResetPswMailFormFragment, _signupForm.signupFormFragment, _loginFrom.loginFormFragment);
exports.formFragment = formFragment;